import { ErrorInfo } from 'react';

import { trackError } from './trackError';

function wrapComponentError(error: Error, info: ErrorInfo) {
  const renderingError = new Error(error.message);
  renderingError.name = 'ReactRenderingError';
  renderingError.stack = info.componentStack;
  renderingError.cause = error;

  return renderingError;
}

export function trackComponentError(error: Error, info: ErrorInfo, { isCrash }: { isCrash?: boolean } = {}) {
  trackError(wrapComponentError(error, info), { isCrash });
}
