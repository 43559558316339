import type { LDContext } from 'launchdarkly-js-client-sdk';

import { createConstantFunction } from './createConstantFunction';

export const accelerateBaseUri = createConstantFunction<string>('accelerateBaseUri');
export const accountId = createConstantFunction<string>('accountId', { optional: true });
export const algoliaApiKey = createConstantFunction<string>('algoliaApiKey');
export const algoliaAppId = createConstantFunction<string>('algoliaAppId');
export const allClientSideFlags = createConstantFunction<{ [key: string]: unknown }>('allClientSideFlags');
export const amberfloSessionToken = createConstantFunction<string>('amberfloSessionToken', { optional: true });
export const assetBase = createConstantFunction<string>('assetBase');
export const assetPath = createConstantFunction<string>('assetPath');
export const backendVersion = createConstantFunction<string>('backendVersion');
export const baseUri = createConstantFunction<string>('baseUri');
export const canduClientToken = createConstantFunction<string>('canduClientToken');
export const clientSideId = createConstantFunction<string>('clientSideId');
export const courierBrandId = createConstantFunction<string>('courierBrandId', { optional: true });
export const courierClientKey = createConstantFunction<string>('courierClientKey', { optional: true });
export const courierPushUserSignature = createConstantFunction<string>('courierPushUserSignature', { optional: true });
export const datadogEnvironment = createConstantFunction<string>('datadogEnvironment');
export const datadogRUMApplicationId = createConstantFunction<string>('datadogRUMApplicationId');
export const datadogRUMClientToken = createConstantFunction<string>('datadogRUMClientToken');
export const datadogSite = createConstantFunction<string>('datadogSite');
export const disallowSignups = createConstantFunction<boolean>('disallowSignups');
export const documentationUri = createConstantFunction<string>('documentationUri');
export const dogfoodBaseUri = createConstantFunction<string>('dogfoodBaseUri');
export const dogfoodClientSideEventsUri = createConstantFunction<string>('dogfoodClientSideEventsUri');
export const dogfoodContext = createConstantFunction<LDContext>('dogfoodContext');
export const dogfoodSendEvents = createConstantFunction<boolean>('dogfoodSendEvents');
export const dogfoodStreamUri = createConstantFunction<string>('dogfoodStreamUri');
export const enableFlagSessionReplay = createConstantFunction<boolean>('enableFlagSessionReplay');
export const enableFullstoryFlagVarsIntegration = createConstantFunction<boolean>('enableFullstoryFlagVarsIntegration');
export const federalCssStylesEnabled = createConstantFunction<boolean>('federalCssStylesEnabled');
export const frontendDatadogFlagRumTracking = createConstantFunction<boolean>('frontendDatadogFlagRumTracking');
export const frontendVersion = createConstantFunction<string>('frontendVersion');
export const ghosting = createConstantFunction<boolean>('ghosting', { optional: true });
export const googleCaptchaSiteKey = createConstantFunction<string>('googleCaptchaSiteKey');
export const googleOauthClientId = createConstantFunction<string>('googleOauthClientId', { optional: true });
export const hasLegacyPlan = createConstantFunction<boolean>('hasLegacyPlan', { optional: true });
export const intercomFinAppAppId = createConstantFunction<string>('intercomFinAppAppId', { optional: true });
export const intercomFinAppUserIdentityHash = createConstantFunction<string>('intercomFinAppUserIdentityHash', {
  optional: true,
});
export const isAccountLapsed = createConstantFunction<boolean>('isAccountLapsed', { optional: true });
export const isManagedInstance = createConstantFunction<boolean>('isManagedInstance');
export const isTrialAccount = createConstantFunction<boolean>('isTrialAccount', { optional: true });
export const minPasswordLength = createConstantFunction<number>('minPasswordLength');
export const passwordMinCharsPerClass = createConstantFunction<number>('passwordMinCharsPerClass');
export const passwordMinClasses = createConstantFunction<number>('passwordMinClasses');
export const planInfo = createConstantFunction<string>('planInfo', { optional: true });
export const secureModeContextHash = createConstantFunction<string>('secureModeContextHash');
export const slackAppHost = createConstantFunction<string>('slackAppHost');
export const slackAppId = createConstantFunction<string>('slackAppId');
export const subscriptionState = createConstantFunction<string>('subscriptionState', { optional: true });
export const userAvatarUrl = createConstantFunction<string>('userAvatarUrl', { optional: true });
export const userEmail = createConstantFunction<string>('userEmail', { optional: true });
export const userId = createConstantFunction<string>('userId', { optional: true });
export const ldvov = createConstantFunction<string>('ldvov', { optional: true });
export const stripePublishableKey = createConstantFunction<string>('stripePublishableKey', { optional: true });
export const pubnubSubscribeKey = createConstantFunction<string>('pubnubSubscribeKey');
export const segmentWriteKey = createConstantFunction<string>('segmentWriteKey');
export const uberfunnelTrackingId = createConstantFunction<string>('uberfunnelTrackingId');
export const identifyTraits = createConstantFunction<{ [key: string]: unknown }>('identifyTraits', { optional: true });
export const groupTraits = createConstantFunction<{ [key: string]: unknown }>('groupTraits', { optional: true });
export const enabledSSOConfig = createConstantFunction<boolean>('enabledSSOConfig', { optional: true });
export const beastModeExpiration = createConstantFunction<number>('beastModeExpiration', { optional: true });

export { mockConstants } from './mockConstants';
